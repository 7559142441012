










import ConfirmationMessage from '@/components/auth/ConfirmationMessage.vue'
import Vue from 'vue'
export default Vue.extend({
    name: 'AuthConfirmationMessagePage',
    components: {
        ConfirmationMessage
    }   
})
